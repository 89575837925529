import * as React from "react"
import Layout from "../components/Layout"
import Breadcrumbs from "../components/Breadcrumbs"
import { StaticImage } from "gatsby-plugin-image"
import { SEO } from "../components/seo"

const ONamaPage = () => {
  return (
    <>
      <Layout
        backArrow={true}
        bodyClasses={" flat-menu-page -white-third-bg meny-right "}
      >
        <Breadcrumbs currentPage="O nama" />
        <main className="main-content">
          <div className="container">

            <h1 className="title-of-page">Naša priča</h1>

            <section className="company-info-section -bottom-indent">
              <div className="row">
                <div className="col-12 col-lg-6 about__company-content prica-tekst">
    {/*
                  <h2 className="title-section">Novi ukusi na starom Dorćolu</h2>

                  <div className="text-block -no-margin">
                    <p>Barrel House je kuća dobrih ukusa, koja se prostire na čak 500 kvadratnih metara.</p>
                    <p>Bilo da ste ljubitelj nacionalne kuhinje ili više volite spoj različtih svetskh trendova… Ovde ćete pronaći svoju čašicu ukusa!</p>
                    <p>Za posebne trenutke koje želite da podelite sa dragon ljudima, bilo da je u pitanju rođendan, poslovna prilika, godišnjica ili venčanje, Barrel House je prava mera zadovoljstva.</p>
                    <p>Rezervište svoje mesto, sve ostalo prepustite nama!</p>
                    <p>Telefon za rezervacije: 060/44-66-039</p>
                  </div>
                */}
                  <div className="text-block -no-margin">
                    <p>Naša istorija je kao čašica vrhunske rakije. Kratka, ali ispunjava toplinom od glave do pete.</p>
                    <p>Najbolje ideje su one koje su najbolje i kada malo “odleže”. To važi za sve u životu, jer je tajni sastojak svakog uspeha - vreme.</p>
                    <p>Tako je i naša ideja o originalnom konceptu koji je mnogo više od restorana, odležala taman koliko je trebalo. Sazrela je dovoljno da nam ulije optimizam, pa je naše bure uvek do pola puno. To je ono što nas pokreće i daje nam žestinu.</p>
                    <p>Barrel House je zamišljen kao mesto koje udružuje najbolje. Najbolje ukuse, najbolju atmosferu i one koji to umeju najbolje da prepoznaju. </p>
                  </div>
                </div>

                <div className="col-sm-7 col-lg-6 col-xl-5 offset-xl-1 prica-foto">
                  <StaticImage
                    src="../images/pocetna-nasa-prica-2.jpg"
                    alt=""
                    width={500}
                    placeholder="blurred"
                    loading="eager"
                  />
                </div>
              </div>

              <div className="mt-5"></div>

              <div className="row">
                <div className="col-sm-7 col-lg-5 col-xl-5 kuvar-foto">
                  <StaticImage
                    src="../images/pocetna-kuvar-1.jpg"
                    alt=""
                    width={500}
                    placeholder="blurred"
                    loading="eager"
                  />
                </div>

                <div className="col-12 col-lg-6 offset-xl-1 kuvar-tekst">
                  <h2 className="title-section" style={{color: '#cc8648'}}>Šef kuhinje</h2>

                  <div className="text-block -no-margin">
                    <p>Kada je neko zaposlen u kuhinji od svoje 17. godine, a sa nepunih 33 je već radio kao kuvar na Bledu, internacionalnim kruzerima i prestižnim beogradskim restoranima, jasno je o kakvom majstoru kuhinje je reč.</p>
                    <p>Naš šef kuhinje, Marko, dan započinje na pijaci, lično birajući samo najkvalitetnije namirnice od kojih kasnije tokom dana stvara kulinarsku čaroliju.</p>
                    <p>Tajni sastojak kuhinje u Barrel House-u je vreme. Kao što je za vrhunsku rakiju potrebno vreme da odleži u buretu, tako je i za vrhunska jela potrebno strpljenje.</p>
                    <p>Ovakvu pasiju, vrhunski profesionalizam i posvećenost misiji naše kuhinje, ipak najbolje oslikavaju upravo reči našeg Šefa:</p>
                    <p style={{fontStyle: 'italic'}}>Specifičnost kuhinje Barrel House-a se ogleda u tome što su u pripremi svakog jela uključujeni i procesi koji ponekad traju i 48 sati. Za slatko od crnih oraha je, recimo, potrebno 3 meseca da se napravi. Svaka vrsta mesa, na primer, ima svoje vreme koje mu je potrebno da odleži u, za taj konkretni komad, posebno pripremljenom miksu začina. Puno ljubavi, iskustva i strpljenja je bilo potrebno za stvaranje našeg menija. On predstavlja rezultat mnogih proba i testiranja koje smo izveli u procesu postavljanja kuhinje kako bi se odlučili za onaj koji, po našem skromnom mišljenju, najbolje oslikava naš doživljaj hrane koji želimo da podelimo sa našim gostima.</p>
                    <p style={{fontStyle: 'italic'}}>Ovim pristupom smo stvorili jelovnik koga ističe raznovrsnost, kako izbora jela, tako i jedinstveni ukus onih već poznatih, a koja se mogu probati u Beogradu. Poseban plezir čini i to što svaki obrok možete započeti rakijom iz bogate ponude naše destilerije.</p>
                  </div>
                </div>
              </div>

              <div className="mt-5"></div>

              <div className="row">
                <div className="col-12 col-lg-6 destilerija-tekst">
                  <h2 className="title-section" style={{color: '#cc8648'}}>Belgrade Urban Distillery</h2>

                  <div className="text-block -no-margin">
                    <p>Belgrade Urban Distillery je jedinstveni koncept zanatske destilerije koji je posvećen proizvodnji preko 20 vrsta rakija i žestokih pića, i to u ograničenim serijama!</p>
                    <p>Voće koje koristimo uzgajamo sami, na jugu Srbije, spajajući tradiciju i najsavremenije tehnologije. Od takvog voća, najboljeg kvaliteta, stvaramo rakiju koja je postala prepoznatljiva nepcima brojnih ljubitelja vrhunskog pića.</p>
                    <p>Od Brankove rakije, prve i jedine sa čak 40% alkohola, koja se služi sa ledom, do prve domaće Banke rakije, Belgrade Urban Distillery je koncept koji je Beogradu doneo potpuno novo iskustvo uživanja u rakiji.</p>
                    <p>Mogli smo da pišemo još, ali najbolje je da nas posetite i, uz naše stručnjake, doživite mali obilazak destilerije… Imaju štošta da vam ispričaju!</p>
                  </div>
                </div>

                <div className="col-sm-7 col-lg-5 col-xl-5 offset-xl-1 destilerija-foto">
                  <StaticImage
                    src="../images/destilerija.jpg"
                    alt=""
                    width={500}
                    placeholder="blurred"
                  />
                </div>
              </div>

              <div className="mt-5"></div>

              <div className="row">
                <div className="col-sm-7 col-lg-5 col-xl-5 buba-foto">
                  <StaticImage
                    src="../images/lenja-buba.jpg"
                    alt=""
                    width={500}
                    placeholder="blurred"
                  />
                </div>

                <div className="col-12 col-lg-6 offset-xl-1 buba-tekst">
                  <h2 className="title-section" style={{color: '#cc8648'}}>Koktel bar Lenja Buba</h2>

                  <div className="text-block -no-margin">
                    <p>Za sve vas koji ste lenji za gužvu, ciku i vrisku, pridružite se i ostalim “lenjim bubama” i uživajte u koktel baru u kojem je broj gostiju ograničen na svega 12, tako da svaki gost dobija potpunu pažnju naših koktel majstora!</p>
                    <p>Iskusno preporučujemo neki od koktela začinjen rakijom naše destilerije.</p>
                  </div>
                </div>
              </div>

            </section>

    {/*
            <section className="our-visitors-section -bottom-indent">
              <h2 className="title-section" style={{color: '#cc8648'}}>Our visitors about us</h2>
              <div className="slider-wrap">

                <div className="row">
                  <div className="col-12 col-md-10">
                    <div className="our-visitors-slider -slider-init">
                      <div>
                        <div className="our-visitors-card">
                          <div className="photo-wrap">
                            <img src="https://via.placeholder.com/165x165" alt="photo" />
                          </div>
                          <div className="our-visitors-text-block">
                            <h3 className="small-title">Jonathan Hook</h3>
                            <div className="text-block">
                              <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p>
                            </div>
                            <div className="social-icons-wrap">
                              <ul className="social-icons -lg -border">
                                <li><a href="javascript:void(0);" target="_blank"><span className="icon-social_facebook"></span></a></li>
                                <li><a href="javascript:void(0);" target="_blank"><span className="icon-social_twitter"></span></a></li>
                                <li><a href="javascript:void(0);" target="_blank"><span className="icon-social_instagram"></span></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="our-visitors-card">
                          <div className="photo-wrap">
                            <img src="https://via.placeholder.com/165x165" alt="photo" />
                          </div>
                          <div className="our-visitors-text-block">
                            <h3 className="small-title">Bob Cupper</h3>
                            <div className="text-block">
                              <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit.</p>
                            </div>
                            <div className="social-icons-wrap">
                              <ul className="social-icons -lg -border">
                                <li><a href="javascript:void(0);" target="_blank"><span className="icon-social_facebook"></span></a></li>
                                <li><a href="javascript:void(0);" target="_blank"><span className="icon-social_twitter"></span></a></li>
                                <li><a href="javascript:void(0);" target="_blank"><span className="icon-social_instagram"></span></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="our-visitors-card">
                          <div className="photo-wrap">
                            <img src="https://via.placeholder.com/165x165" alt="photo" />
                          </div>
                          <div className="our-visitors-text-block">
                            <h3 className="small-title">Maik Rigger</h3>
                            <div className="text-block">
                              <p>Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</p>
                            </div>
                            <div className="social-icons-wrap">
                              <ul className="social-icons -lg -border">
                                <li><a href="javascript:void(0);" target="_blank"><span className="icon-social_facebook"></span></a></li>
                                <li><a href="javascript:void(0);" target="_blank"><span className="icon-social_twitter"></span></a></li>
                                <li><a href="javascript:void(0);" target="_blank"><span className="icon-social_instagram"></span></a></li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          */}
          </div>

        </main>
      </Layout>
    </>
  )
}

export default ONamaPage

export const Head = () => (
  <SEO />
)
